/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-10-19 16:31:37
 * @LastEditors: zhangguoliang
 * @LastEditTime: 2021-11-19 17:50:29
 */
import http from '@/utils/http';

// 工程项目点击编辑获取详情
export function coccMtdsResource_project_statistics(): any {
  return http.get(`/cocc-sds-resource/1/project/statistics`);
}

/**
 * 用户是否确认过免责声明
 * @returns
 */
export function coccMtdsResource_user_liabilityExemptionFirst() {
  return http.get<boolean>(
    '/cocc-mtds-resource/1/user/liability-exemption/first'
  );
}

/**
 * 同意确认免责声明
 * @returns
 */
export function coccMtdsResource_user_liabilityExemptionConfirm() {
  return http.get<string>(
    '/cocc-mtds-resource/1/user/liability-exemption/confirm'
  );
}

/**
 * 获取首页个人数据
 * @returns
 */
export function agencies_benefitBaseInfo() {
  return http.get<string>('/cocc-mtds-resource/1/agencies/benefit/base-info');
}
